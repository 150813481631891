<template>
  <div class="container">
    <div class="top">
      <h1>全国建筑企业资质查询系统</h1>
      <p class="enName">
		China Construction Enterprise Qualification Inquiry System
      </p>
      <div class="search">
        <el-input
          placeholder="请输入企业全称进行查询"
          v-model="searchTxt"
        ></el-input>
        <span @click="searchResult">查询</span>
      </div>
      <div class="desc">
        <ul class="desc-list">
          <li>
            <img src="../assets/images/1.jpg" />
            <p class="content">支持多种查询，无需任何注册、登录，完全免费</p>
          </li>
          <li>
            <img src="../assets/images/2.jpg" />
            <p class="content">多维度整合关联信息，智能匹配搜索结果</p>
          </li>
          <li>
            <img src="../assets/images/3.jpg" />
            <p class="content">数据实时更新</p>
          </li>
        </ul>
      </div>
      <div class="linkBox">
        <p class="title">友情链接</p>
        <div class="line"></div>
        <ul>
          <li>
            <a href="http://www.mohurd.gov.cn/" target="_blank" ref ="nofollow">中华人民共和国住房和城乡建设部</a>
          </li>
          <li>
            <a href="http://www.ceqmc.org/" target="_blank" ref ="nofollow">建筑业企业资质管理中心</a>
          </li>
          <li>
            <a href="https://www.cari.net.cn/" target="_blank" ref ="nofollow">中国建筑研究院</a>
          </li>
          <li>
            <a href="http://www.bjjs.gov.cn/" target="_blank" ref ="nofollow">北京市住房和城乡建设委员会</a>
          </li>
          <li>
            <a href="http://www.tjcac.gov.cn/" target="_blank" ref ="nofollow">天津市城乡建设和交通委员会</a>
          </li>
          <li>
            <a
              href="http://www.shjjw.gov.cn/gb/node2/index.html" target="_blank" ref ="nofollow">上海市住房和城乡建设管理委员会</a>
          </li>
          <li>
            <a href="http://www.ccc.gov.cn/" target="_blank" ref ="nofollow">重庆市城乡建设委员会</a>
          </li>
          <li>
            <a href="http://www.hebjs.gov.cn/" target="_blank" ref ="nofollow">河北省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.sxjs.gov.cn/Main/main.action" target="_blank" ref ="nofollow">山西省住房和城乡建设厅</a>
          </li>
          <li>
            <a
              href="http://www.nmgjsw.gov.cn/website/main/index.aspx" target="_blank" ref ="nofollow">内蒙古自治区住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.hljjs.gov.cn/" target="_blank" ref ="nofollow">黑龙江省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://jst.jl.gov.cn/" target="_blank" ref ="nofollow">吉林省住房和城乡建设厅</a>
          </li>
          <li>
            <a
              href="http://www.lnjst.gov.cn/website/main/index.aspx" target="_blank" ref ="nofollow">辽宁省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.sdjs.gov.cn/" target="_blank" ref ="nofollow">山东省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://jsszfhcxjst.jiangsu.gov.cn/" target="_blank" ref ="nofollow">江苏省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.ahjst.gov.cn/ahzjt_Front/" target="_blank" ref ="nofollow">安徽省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.zjjs.com.cn/" target="_blank" ref ="nofollow">浙江省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.fjjs.gov.cn/" target="_blank" ref ="nofollow">福建省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.jxjst.gov.cn/" target="_blank" ref ="nofollow">江西省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.hnjs.gov.cn/" target="_blank" ref ="nofollow">河南省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.hbzfhcxjst.gov.cn" target="_blank" ref ="nofollow">湖北省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.hunanjs.gov.cn/" target="_blank" ref ="nofollow">湖南省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.gdcic.gov.cn/default.html" target="_blank" ref ="nofollow">广东省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.gxzjt.gov.cn/" target="_blank" ref ="nofollow">广西壮族自治区住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.hnjst.gov.cn/" target="_blank" ref ="nofollow">海南省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.ynjst.gov.cn/" target="_blank" ref ="nofollow">云南省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.gzjs.gov.cn/" target="_blank" ref ="nofollow">贵州省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.scjst.gov.cn/" target="_blank" ref ="nofollow">四川省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.xzcs.gov.cn/" target="_blank" ref ="nofollow">西藏自治区住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.shaanxijs.gov.cn/" target="_blank" ref ="nofollow">陕西省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.gsjs.com.cn/" target="_blank" ref ="nofollow">甘肃省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.nxjst.gov.cn/" target="_blank" ref ="nofollow">宁夏回族自治区住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.qhcin.gov.cn/" target="_blank" ref ="nofollow">青海省住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.xjjs.gov.cn/" target="_blank" ref ="nofollow">新疆维吾尔自治区住房和城乡建设厅</a>
          </li>
          <li>
            <a href="http://www.xjbt.gov.cn/" target="_blank" ref ="nofollow">新疆生产建设兵团</a>
          </li>
        </ul>
        <div class="footer"></div>
        <p class="footer-ban">
          <span>&copy;{{ time }} &ensp; <a href="https://beian.miit.gov.cn" target="_blank" ref ="nofollow">浙ICP备17042384号-2</a></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTxt: "",
      time: "",
    };
  },
  methods: {
    searchResult() {
      if (!this.searchTxt) {
        this.$message.warning("请输入企业全称进行查询");
        return;
      } else {
        this.$router.push({
          path: "/result",
          query: { search: this.searchTxt },
        });
      }
    },
  },
  mounted() {
    var date = new Date();
    this.time = date.getFullYear();
  }
};
</script>

<style lang="scss" scoped>
.container {
  color: #2c3e50;
  height: 100%;
  min-width: 1200px;
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  .top {
    width: 100%;
    background: url("../assets/images/bg.jpg") no-repeat 50% 0;
    padding-top: 200px;
  }
  h1 {
    color: #d82d2c;
    font-size: 44px;
    // letter-spacing: 4px;
    margin: 0;
  }
  .enName {
    color: #d82d2c;
    padding-top: 20px;
    font-size: 20px;
  }
  .search {
    position: relative;
    width: 860px;
    margin: 80px auto 30px;
    height: 60px;
    span {
      display: inline-block;
      color: #fff;
      height: 60px;
      line-height: 60px;
      width: 180px;
      background-color: #d82d2c;
      position: absolute;
      right: -2px;
      bottom: 0;
      letter-spacing: 6px;
      cursor: pointer;
      font-size: 22px;
    }
  }
  .el-input {
    width: 100%;
    text-align: left;
    height: 56px;
    line-height: 56px;
    border: 2px solid #d82d2c;
  }

  /deep/.el-input__inner {
    line-height: 56px;
    height: 56px;
    border: 0;
    outline: 0;
    font-size: 20px;
    color: #333;
  }
  .desc-list {
    display: inline-block;
    font-size: 14px;
    color: #666666;
    margin: 20px auto;
    white-space: nowrap;
    text-align: left;
    li {
      padding: 10px;
      width: 150px;
      float: left;
      text-align: center;
      margin: 0 40px;
      position: relative;
      p {
        white-space: normal;
        word-break: break-all;
        text-align: center;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .desc-list li:not(:last-child)::after {
    content: "";
    display: block;
    width: 2px;
    height: 95px;
    background-color: #fafafa;
    position: absolute;
    right: -46px;
    top: 10px;
  }
  .linkBox {
    width: 100%;
    background: #f5f5f5;
    overflow: hidden;
    .title {
      font-size: 24px;
      color: #000;
      line-height: 60px;
    }
    .line {
      border: 1px solid #d82d2c;
      width: 60px;
      margin: 0 auto;
    }
    ul {
      width: 1000px;
      overflow: hidden;
      margin: 50px auto;
      li {
        padding: 10px 5px;
        width: 240px;
        float: left;
        text-align: left;
      }
    }
  }

  .footer {
    width: 100%;
    border: 1px solid #d82d2c;
  }
  .footer-ban {
    line-height: 50px;
	font-size: 14px;
  }
}
</style>
